<template>
  <div id="order-confirm" class="full-width fx-main pd-0 mg-2">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div class="container">
      <div class="cart-checkout-options fx fx-direct-cont">
        <div class="txt-align-center">
          <span>Verifique seu Pedido</span>
        </div>
      </div>

      <p
        class="txt-align-center"
      >Verifique atentamente os dados e detalhes, e certifique-se que estão corretos antes de confirmar o seu pedido.</p>
      <div>
        <div
          v-for="supplierAgg in supplierAggs"
          :key="supplierAgg.supplier.id"
          class="fx fx-direct-cont"
        >
          <div class="order-list col-md-10 cart-item dsp-grid gd-tp-c-2">
            <div
              v-for="orderRow in supplierAgg.items"
              :key="orderRow.product.id + String(orderRow.variation ? orderRow.variation.id : '')"
              class="dsp-grid order-confirm-tpl-grid wd-100 pd-05-em green-shadow"
            >
              <div class="cart-p-img fx-center">
                <img :src="buildImgUrl(orderRow)" />
              </div>

              <div class="fx fx-main f-j-c-c cart-block-p-name-price fx-a-i-c fx-start">
                <div class="product-name">
                  <span class="mg-right-1-em">
                    {{ orderRow.product.name }}
                    <template
                      class="mg-right-1-em"
                      v-if="orderRow.variation"
                    >&nbsp; - {{ orderRow.variation.name }}</template>
                  </span>
                </div>

                <div class="cart-price">
                  <!-- <div class="cart-price" v-if="orderRow.full_price != orderRow.unit_price"> -->
                  <span class="cart-price mg-right-1-em">{{ orderRow.quantity }}</span>
                  <span class="cart-price mg-right-1-em">x</span>
                  <span class="cart-price product-price">{{ orderRow.unit_price | toCurrency }}</span>
                </div>

                <!-- <div class="cart-price">
                  <span class="product-price"> {{ orderRow.full_price | toCurrency }}</span>
                </div>-->
              </div>

              <!-- <div v-if="!assumeSingleSupplier">
                  <span>{{ supplierAgg.supplier.name }}</span>
              </div>-->
            </div>
          </div>
        </div>
        <div class="mg-1-top">
          <div class="cart-checkout-options fx fx-direct-cont">
            <div class="txt-align-center">
              <span class="text-confirm">Confirmação do Pedido</span>
            </div>
          </div>

          <div class="fx mg-top-1 wd-100 fx-end">
            <div class="fx fx-direct-cont wd-20">
              <div class="dsp-grid gd-tp-c-2 pd-05-em">
                <span>Subtotal</span>
                <!--------------- Without Plotted Price ------------------------------------------------------------>
                <span v-if="!order.enabled_plotted" class="main-title">{{ orderItemsTotalPrice | toCurrency }}</span>
                <!--------------- Plotted Price -------------------------------------------------------------------->
                <span v-if="order.enabled_plotted" class="main-title">{{ parseInt(orderItemsTotalPrice) + parseInt(order.plotted_amount) | toCurrency }}</span>
              </div>
              <div class="dsp-grid gd-tp-c-2 pd-05-em">
                <span>Entrega</span>
                <span class="main-title">{{ orderShippingPrice | toCurrency }}</span>
              </div>
              <div class="dsp-grid gd-tp-c-2 pd-05-em">
                <span>Descontos</span>
                <span
                  class="main-title"
                >{{ ((orderItemsTotalPrice + orderShippingPrice)) - order.full_amount | toCurrency }}</span>
              </div>
              <div class="dsp-grid gd-tp-c-2 pd-05-em">
                <span>Total</span>
                <!--------------- Without Plotted Price ----------------------------------------------------------------->
                <span v-if="!order.enabled_plotted" class="main-title">{{ order.full_amount | toCurrency }}</span>
                <!--------------- Plotted Price ----------------------------------------------------------------->
                <span v-if="order.enabled_plotted" class="main-title">{{ parseInt(order.full_amount) + parseInt(order.plotted_amount) | toCurrency }}</span>
              </div>
            </div>
          </div>

          <!-- <template v-if="Number(order.shipping_amount) > 0 || Number(orderShippingPrice) > 0">
            
            <h4 class="mg-2">Frete: {{ orderShippingPrice | toCurrency }}</h4>

            <h4
              class="mg-2"
              v-if="(orderShippingPrice - order.shipping_amount) > 0"
            >Desconto no Frete: {{ (orderShippingPrice - order.shipping_amount) | toCurrency }}</h4>

            <h4 class="mg-2">Frete Final: {{ order.shipping_amount | toCurrency }}</h4>

            <span>
              Entrega <span class='main-title'>{{ orderShippingPrice | toCurrency }} </span>
          </span>
          </template>-->

          <!-- <h3>Produtos</h3>
          <h4 class="mg-2">Produtos: {{ orderItemsTotalPrice | toCurrency }}</h4>
          <h4
            class="mg-2"
            v-if="(orderItemsTotalPrice - order.items_amount) > 0"
          >Desconto nos Produtos: {{ (orderItemsTotalPrice - order.items_amount) | toCurrency }}</h4>
          <h4 class="mg-2">Custo Final dos Produtos: {{ order.items_amount | toCurrency }}</h4>

          <h3>Total</h3>
          <h4 class="mg-2">Total do Pedido: {{ order.full_amount | toCurrency }}</h4>-->
        </div>

        <div class="fixed-buttons fixed container fx fx-end">
          <div>
            <a class="p-detail-btn clickable" @click="confirmOrder">
              Confirmar Pedido
              <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.order-confirm-tpl-grid {
  grid-template-columns: 5em 1fr;
}

@media (max-width: 500px) {
  .wd-20 {
    width: 100% !important;
  }
}
</style>

<script>
import Order from "@/services/order.js";
import { debounce } from "debounce";
import router from "@/router";
import User from "@/services/user.js";
import util from "@/services/util";
import boolean from "boolean";

import Loading from "vue-loading-overlay";

import { buildImgUrl } from "@/services/item";

var groupBy = function (arr, key) {
  return arr.reduce(function (agg, x) {
    var actualKey = typeof key === "function" ? key(x) : key;
    (agg[actualKey] = agg[actualKey] || []).push(x);
    return agg;
  }, {});
};

export default {
  name: "OrderConfirm",
  template: "#order-confirm",
  data: function () {
    return {
      isLoading: false,
      order: {},
      cachedSuppliers: {},
      supplierAggs: [],
      assumeSingleSupplier: boolean(process.env.VUE_APP_ASSUME_SINGLE_SUPPLIER),
      autoConfirmOrder: boolean(process.env.VUE_APP_AUTO_CONFIRM_ORDER),
    };
  },
  props: {
    order_id: String,
    order_object: Object,
  },
  computed: {
    orderItemsTotalPrice: function () {
      if (!this.order || !this.order.items) {
        return 0;
      }
      return this.order.items
        .map((i) => Number(i.full_price))
        .reduce((a, b) => a + b, 0);
    },
    orderShippingPrice: function () {
      if (!this.order || !this.order.supplier_entries) {
        return 0;
      }
      return this.order.supplier_entries
        .map((i) => Number(i.shipping_cost))
        .reduce((a, b) => a + b, 0);
    },
  },
  mounted: async function () {
    new Promise((resolve, reject) => {
      if (this.order_object) {
        resolve((this.order = this.order_object));
      } else {
        Order.get(this.order_id)
          .then((order) => {
            resolve((this.order = order));
          })
          .catch(() => {
            router.push({
            name: "thank-you",
            params: {
              order_id: 'awaiting-confirmation'
            },
          });
          })
      }
    }).then(async (ord) => {
      if (ord.status != "AWAITING_CONFIRMATION") {
        this.pushAppropriately(ord);
        return;
      }
      if(this.autoConfirmOrder) {
        this.confirmOrder();
        return;
      }
      var grouped = groupBy(ord.items, (i) => i.product.supplier_id);
      var aggs = await Object.keys(grouped).map(async (key, index) => {
        var sup = this.cachedSuppliers[key];
        if (!sup) {
          sup = await User.get(key);
          this.cachedSuppliers[key] = sup;
        }
        return {
          supplier: sup,
          items: grouped[key],
        };
      });
      this.supplierAggs = [];
      aggs.map(async (p) => this.supplierAggs.push(await p));
    });
  },
  components: {
    Loading,
  },
  methods: {
    buildImgUrl: function (item) {
      return buildImgUrl(item);
    },
    pushAppropriately: (order) => {
      if (order.payment_method === "no_op") {
        router.push({
          name: "thank-you",
          params: {
            order_id: order.id,
            order_object: order,
          },
        });
        return;
      }
      switch (order.status) {
        case "AWAITING_PAYMENT":
        case "PROCESSING":
          router.push({
            name: "payment",
            params: {
              order_id: order.id,
              order_object: order,
            },
          });
          break;
        case "PAID":
          router.push({
            name: "thank-you",
            params: {
              order_id: order.id,
              order_object: order,
            },
          });
          break;
        case "AWAITING_CONFIRMATION":
          router.push({
            name: "thank-you",
            params: {
              order_id: 'awaiting-confirmation',
              // order_object: order,
            },
          });
          break;
        default:
          
          break;
      }
    },
    confirmOrder: debounce(
      function () {
        if (this.isLoading) {
          return;
        }
        this.isLoading = true;
        Order.confirm(this.order.id)
          .then(
            (data) => {
              this.$root.$emit("cartUpdate");
              this.pushAppropriately(data);
            },
            (error) => {
              if (error.response.status === 500) {
                return router.push({
                  name: "thank-you",
                  params: {
                    order_id: 'awaiting-confirmation'
                  },
                });
              }

              this.$notify({
                type: "error",
                duration : 24000,
                title: "Erro ao completar o pedido!",
                text: util.stringifyAxiosError(error),
              });
            }
          )
          .finally(() => {
            this.isLoading = false;
            this.$root.$emit("cartUpdate");
          });
      },
      10000,
      true
    ),
  },
};
</script>